@charset "utf-8";
@import url("https://fonts.googleapis.com/css?family=Nanum+Gothic:400,700&display=swap");

.weekly-page {
  background: #e8e8e8;
  padding: 38px 18px;
  max-width: 600px;
  margin: 0 auto;
}

#header {
  margin: 0 22px;
}

#header .logo {
  /* width: 80px; */
  width: 220px;
  margin-bottom: 35px;
}

#header h3 {
  font-weight: medium;
  font-size: 22px;
  letter-spacing: -5%;
  line-height: 30px;
  margin-bottom: 56px;
}

#header h3 span {
  font-size: 16px;
  font-weight: lighter;
  color: #5b7784;
  margin: 0 4px;
}

.segment {
  background: #ffffff;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 18px 22px;
  margin-bottom: 10px;
  position: relative;
}

.segment > h3 {
  /* font-family: Apple SD Gothic Neo; */
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.05em;
  color: #5b7784;
}

.segment > h3 > span {
  font-size: 16px;
}

.msg {
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #222222;
  margin-top: 40px;
  margin-bottom: 34px;
}

.segment--total {
  padding-bottom: 64px;
}
#totalgraph {
  background: #e7e6e6;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  height: 0;
  overflow: hidden;
  padding-bottom: 45%;
}

#totalgraph__bg {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#totalgraph__graph {
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  transform-origin: 50% 80%;
  /* transform: rotate( ${ props => -180 * ( 100 -props.value ) / 100 }deg); */
}

#totalgraph__arrow {
  z-index: 3;
  top: 66%;
  left: 0;
  width: 100%;
  position: absolute;
  transform-origin: 50% 80%;
  /* transform: rotate( ${ props => -180 * ( 100 -props.value ) / 100 }deg); */
}

#totalgraph__number {
  z-index: 4;
  position: absolute;
  top: 64%;
  left: 50%;
  font-size: 70px;
  font-weight: bold;
  color: #222222;
  letter-spacing: -5%;
  transform: translate(-50%, -50%);
}

.msg--total {
  z-index: 5;
  position: absolute;
  top: 76%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

.descs:after {
  clear: both;
  content: "";
  display: block;
}

.desc--1 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 59px;
  letter-spacing: -0.05em;
  float: left;
}

.desc--1 > span {
  font-size: 16px;
  color: #5b7784;
  margin-left: 10px;
}

.desc--2 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #5b7784;
  float: right;
}

.legend {
  position: absolute;
  top: 18px;
  right: 22px;
}

.legend p {
  text-align: right;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;

  color: #5b7784;
}

.legend p sub {
  font-size: 10px;
  vertical-align: baseline;
}

.legend p > span {
  margin-left: 6px;
  display: inline-block;
  width: 13.6px;
  height: 13.6px;
  border-radius: 100%;
  vertical-align: bottom;
}

.legend p:nth-child(1) span {
  background: #0cddc0;
}

.legend p:nth-child(2) span {
  background: #ffd215;
}

.pm25graphs {
  position: relative;
  margin-top: 20px;
}

.pm25graphs > div:nth-child(1) {
  width: 85%;
}

.pm25graphs > div:nth-child(2) {
  position: absolute;
  bottom: 0;
  right: 0;
}

.pm25graphs > div:nth-child(2) p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;

  color: #5b7784;
}

.pm25graphs > div:nth-child(2) p > span {
  margin-left: 6px;
  display: inline-block;
  width: 13.6px;
  height: 13.6px;
  border-radius: 100%;
}

.pm25graphs > div:nth-child(2) p:nth-child(1) span {
  background: #ffd215;
}

.pm25graphs > div:nth-child(2) p:nth-child(2) span {
  background: #0cddc0;
}

.pm25graph {
  margin-bottom: 16px;
}

.pm25graph:after {
  clear: both;
  content: "";
  display: block;
}

.pm25graph__date {
  width: 10%;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-top: 7px;
  margin-right: 10px;
  text-align: center;

  color: #222222;
  float: left;
}

.pm25graph__bar {
  float: left;
  width: 72%;
}

.pm25graph__out {
  height: 15px;
  border-radius: 10px;
  margin-bottom: 5px;
  background: rgb(255, 210, 21);
  background: linear-gradient(90deg, rgba(255, 210, 21, 1) 0%, rgba(255, 210, 21, 1) 50%, rgba(255, 115, 21, 1) 100%);
  width: 100%;
  position: relative;
}

.pm25graph__in {
  height: 15px;
  border-radius: 10px;
  margin-bottom: 5px;
  background: #0cddc0;
  width: 100%;
  position: relative;
}

.pm25graph__value {
  position: absolute;
  right: -80px;
  color: #5b7784;
  margin-top: -5px;
}

.weekly-page .icon {
  position: relative;
  width: 40px;
  vertical-align: -10px;
  margin-left: 18px;
}

.filter {
  width: 40px;
  position: relative;
  overflow: hidden;
  background: #dddddd;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.filter .icon {
  z-index: 3;
  margin: 0;
}

.filter__inner {
  position: absolute;
  display: block;
  content: "";
  width: 40px;
  bottom: 0;
  left: 0;
  background: rgb(255, 210, 21);
  z-index: 2;
}

.desc--2__text {
  position: absolute;
  top: 49px;
  right: 80px;
  text-align: right;
}

.highlight {
  position: relative;
}

#highlightGraph {
  z-index: 1;
  position: relative;
}

.highlight__deco {
  position: absolute;
  top: 0;
  /* left:14px;
    right: 14px; */
  left: 49px;
  right: 44px;
  height: 100%;
  /* background-color:red; */
  z-index: 2;
  /* opacity: .5; */
}

.highlight__deco > .item {
  position: absolute;
  width: 25%;
  height: 96.5%;
  top: 0;
  z-index: 4;
  border-right: 1px dashed #e8e8e8;
  text-align: center;
  font-weight: bold;
  line-height: 1.3em;
  color: #222222;
  font-size: 16px;
}

.highlight__deco .date {
  font-weight: normal;
  color: #5b7784;
  font-size: 12px;
}

.highlight__deco > .item:nth-child(1) {
  border-left: 1px dashed #e8e8e8;
  left: 0 !important;
}

.highlight__deco > .item:nth-child(2) {
  left: 25% !important;
}

.highlight__deco > .item:nth-child(3) {
  left: 50% !important;
}

.highlight__deco > .item:nth-child(4) {
  left: 75% !important;
}

.highlight__deco > .item.active:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #e8e8e8;
  border-radius: 10px;
  opacity: 0.6;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.08);
}

#highlightGraph {
  margin-top: 20px;
  padding-top: 40px;
}

.highlight-msg .co2 {
  color: #0cddc0;
}

.highlight-msg .fan {
  color: #ffd215;
}

.highlight-msg sub {
  font-size: 12px;
  vertical-align: baseline;
}
